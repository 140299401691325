import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { Link } from "react-router-dom";

const GmsLeads = () => {



  const [combinedData, setCombinedData] = useState([]);
  
  // ----------------------------
  
  
  
  const [contact, setContact] = useState([]);

  const loadData = async () => {
    let res = await fetch("https://api.glazma.com/booking-form", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    res = await res.json();
    console.log("landing page:", res.data);
    setContact(res.data.reverse());
  };

  useEffect(() => {
    loadData();
  }, []);


  // ======================================================================
   
// ---------------------- fetch all -------------------------
  
const [booking, setBooking] = useState([]);

const loadWebData = async () => {
  let res = await fetch("https://api.shop.glazma.com/all-bookings", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
    },
  });
  res = await res.json();
  console.log("website :", res.data);
  setBooking(res.data.reverse());
};

useEffect(() => {
  loadWebData();
}, []);


// =======================================================
   
// ---------------------- fetch all -------------------------
  
const [contactUs, setContactUs] = useState([]);

const loadContactUs = async () => {
  let res = await fetch("https://api.shop.glazma.com/contact-us", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
    },
  });
  res = await res.json();
  console.log("website :", res.data);
  setContactUs(res.data.reverse());
};

useEffect(() => {
    loadContactUs();
}, []);


// =======================================================


// const combined = contact.map((item, index) => ({
//   ...item,
//   ...booking[index], 
// }));
// setCombinedData(combined);

// ===================================================


const combined = [...contact, ...booking, ...contactUs];

// Sort combined data by date (assuming date is in ISO format)
// combined.sort((a, b) => new Date(a.date) - new Date(b.date));

// setCombinedData(combined);

// console.log("first", combined)
combined.sort((a, b) => new Date(b.date) - new Date(a.date))


  
  
  return (
    <div>
        <NavBar/>

<div className="service-heading">Glazma ({combined.length})</div>

<table className="dashboard-table">
  <tr>
    <th>Date</th>
    <th>Name</th>
    <th>Number</th>
    <th>Service</th>
    <th>Price</th>
    <th>City/ Address</th>
    {/* <th>Message</th>
    <th>Page</th> */}

  </tr>

      {
        combined.map(e=>(
          <tr>
            <td> {new Date(e.date).toLocaleDateString('en-GB')}</td>
            {/* <td> {new Date(e.date).toDateString()}</td> */}
            <td>{e.name}</td>
            {/* <td>{e.phone}</td> */}
            <td><Link to={`tel:${e.phone}`} target="_blank">{e.phone}</Link></td>
            <td>
                {
                    e.service?e.service:
                    e.cartData?
                    e.cartData.map((e, i) => (
                          <>{e.fullName}, </>
                        // <div className="admin-data-list-col-service-list">
                        //   <div>{i+1}</div>
                        //   <div>{e.price}</div>
                        //   <div>{e.quantity}</div>
                        //   <div>{`${e.price * e.quantity}`}</div>
                          
                        // </div>
                      ))
                      :
                      e.subject?<><b>Contact Us Query</b> - {e.message}</>
                      
                    //   e.subject
                      :"ok"
                }
            </td>
            <td>{e.totalPrice}</td>
            {/* <td>{e.bike=="other"?"other model":e.bike}</td> */}
            <td>{e.city || e.address}</td>
            {/* <td>{e.bikeCC}</td> */}
            {/* <td>Be On Time</td>
            <td>Landing Page</td> */}
            {/* <br /> */}
          </tr>
        ))
      }
      
      </table>
      
    </div>
  )
}

export default GmsLeads