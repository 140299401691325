import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='error-page'>
      <h1>ERROR</h1>
        <h2>this page is not valid </h2> 
        <h3><Link to="/">go to home</Link></h3>


    </div>
  )
}

export default Error