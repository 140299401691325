import React from 'react'
import FtCompleteContact from '../../components/ft/FtCompleteContact'
import FtNav from '../../components/ft/FtNav'

const FtCompleteContactPage = () => {
  return (
    <div>
        <FtNav/>
        <FtCompleteContact/>
    </div>
  )
}

export default FtCompleteContactPage