import React from "react";

const PopUpAlert = ({close, deleteAll}) => {
  return (
    <div>
      <div className="popup-alert">
        <div className="popup-alert-content">
          {/* <div className="popup-alert-close"></div> */}
          <div className="popup-alert-heading">want to delete all data?</div>
          <div className="popup-alert-options">
            <div onClick={e=>close(false)}>no</div>
            <span onClick={e=>deleteAll()}>yes</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpAlert;
