import React from 'react'
import FtNav from '../../components/ft/FtNav'
import FtBooking from '../../components/ft/FtBooking'

const FtBookingPage = () => {
  return (
    <div>
        <FtNav/>
        <FtBooking/>
    </div>
  )
}

export default FtBookingPage