import React from 'react'
import FtNav from '../../components/ft/FtNav'
import FtCompletedBooking from '../../components/ft/FtCompletedBooking'

const FtCompletedBookingPage = () => {
  return (
    <div>
        <FtNav/>
        <FtCompletedBooking/>
    </div>
  )
}

export default FtCompletedBookingPage