import React from 'react'
import FtNav from '../../components/ft/FtNav'
import FtPendingContact from '../../components/ft/FtPendingContact'

const FtPendingContactPage = () => {
  return (
    <div>
        <FtNav/>
        <FtPendingContact/>
    </div>
  )
}

export default FtPendingContactPage