import React from 'react'
import GmsNav from '../../components/gms/GmsNav'
import GmsBooking from '../../components/gms/GmsBooking'

const GmsBookingPage = () => {
  return (
    <div>
      <GmsNav/>
      <GmsBooking/>
    </div>
  )
}

export default GmsBookingPage