import React from 'react'
import GocNav from '../../components/goc/GocNav'
import GocContact from '../../components/goc/GocContact'

const GocInquiryPage = () => {
  return (
    <div>
        <GocNav/>
        <GocContact/>
    </div>
  )
}

export default GocInquiryPage