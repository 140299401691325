import React, { useEffect, useState } from "react";


const FtBooking = () => {
    
  // ------------------- popup -------------------

const [popup, setPopup] = useState(false)
const [save, setSave] = useState("")
 

   
    
// ---------------------- fetch all -------------------------
  
  const [contact, setContact] = useState([]);

  const loadData = async () => {
    let res = await fetch("https://api.fluffytouch.in/all-bookings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    res = await res.json();
    console.log("res", res.data);
    setContact(res.data.reverse());
  };

  useEffect(() => {
    loadData();
  }, []);

  

  // -------------------------- update ---------------------------
  
  
 const [update, setUpdate] = useState({
  user: "",
  status: "",
});

const handleChange = (e) => {
  const value = e.target.value;
  setUpdate({
    ...update,
    [e.target.name]: value,
  });
};

const handleSubmit = async (e) => {
  // setLoading(true);

  e.preventDefault();
  try {
    const response = await fetch(`https://api.fluffytouch.in/update-booking/${save._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    });

    if (response.ok) {
      alert("UPDATE successfully");
      window.location.reload();
      // onClose()
    } else {
      // alert("invalid credential...");
      const res_data = await response.json();
      alert(res_data.message);
      // console.log("className=",res_data.message)
    }

    // console.log(response);
  } catch (error) {
    // console.log("catch err", error);
    alert("server error");
  }
  // setLoading(false);
};

  
  
  
  return (
    <div>

    {
        popup===false?("")
        :(
            <div>
              <div className="update-form-main">
            <div className="update-form-content">
              <div className="update-form-close"><span onClick={()=>{setPopup(false)}}>X</span></div>
          <div className="update-form-head">{new Date(save.date).toDateString()} &nbsp; &nbsp; - &nbsp; &nbsp; {new Date(save.date).toLocaleTimeString()}</div>

              <div className="update-form-data">

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Pet</div>
                  <div className="update-form-data-text">{save.pet} &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; {save.breed}</div>
                </div>

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Name</div>
                  <div className="update-form-data-text">{save.name}</div>
                </div>

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Number</div>
                  <div className="update-form-data-text">{save.phone}</div>
                </div>

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Service</div>
                  <div className="update-form-data-text">{save.service}</div>
                </div>

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">AddOns</div>
                  <div className="update-form-data-text">{save.addOns}</div>
                </div>

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Address</div>
                  <div className="update-form-data-text">{save.address}</div>
                </div>

                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Message</div>
                  <div className="update-form-data-text">{save.request}</div>
                </div>
{/*     
                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Date</div>
                  <div className="update-form-data-text">{new Date(save.date).toDateString()}</div>
                </div>
    
                <div className="update-form-data-list">
                  <div className="update-form-data-heading">Time</div>
                  <div className="update-form-data-text">{new Date(save.date).toLocaleTimeString()}</div>
                </div> */}
    
              <form onSubmit={handleSubmit}>
                <div className="update-form-list">
                  <div className="update-form-lable">User</div>
                    <select name="user" required id=""  onChange={handleChange}>
                      <option selected disabled value="">--- select option --</option>
                      <option value="true">Genuine user</option>
                      <option value="false">Fake user</option>
                    </select>
                </div>
    
                <div className="update-form-list">
                  <div className="update-form-lable">Lead</div>
                  <div className="update-form-input">
                    <div><input type="radio" required name="status" value="true"   onChange={handleChange}/><span>booked</span></div>
                    <div><input type="radio" required name="status" value="false"  onChange={handleChange}/><span>Complete</span></div>
                    
                  </div>
                 
                </div>
                <button className="update-form-button"><span>Confirm</span> </button>
    
                
              </form>
            </div>
            </div>
          </div>
            </div>
        )
    }     
          
          
          <div className="service-heading">Fluffy Touch - Bookings</div>
          {/* ------------------ data start -------------------- */}
          <div className="service-list">
            {contact.map((e) => {
              return (
                
                
                <div
                  className={
                    e.user === false
                      ? "service-list-red service-list-col"
                      : e.status === false
                      ? "service-list-green service-list-col"
                      : "service-list-orange service-list-col"
                  }
                >
                  <div className="service-list-head">{new Date(e.date).toDateString()}  &nbsp;  &nbsp; -  &nbsp;  &nbsp; {new Date(e.date).toLocaleTimeString()}</div>
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Pet</div>
                    <div className="service-list-data-text">{e.pet} &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; {e.breed}</div>
                  </div>
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Name</div>
                    <div className="service-list-data-text">{e.name}</div>
                  </div>
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Number</div>
                    <div className="service-list-data-text">{e.phone}</div>
                  </div>
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Service</div>
                    <div className="service-list-data-text">{e.service}</div>
                  </div>
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">AddOns</div>
                    <div className="service-list-data-text">{e.addOns}</div>
                  </div>
                  
                  {/* <div className="service-list-data">
                    <div className="service-list-data-heading">Breed</div>
                    <div className="service-list-data-text">{e.breed}</div>
                  </div> */}
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Address</div>
                    <div className="service-list-data-text">{e.address}</div>
                  </div>
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Message</div>
                    <div className="service-list-data-text">{e.request}</div>
                  </div>
                  
                  {/* <div className="service-list-data">
                    <div className="service-list-data-heading">Date</div>
                    <div className="service-list-data-text">{new Date(e.date).toDateString()}</div>
                  </div>
    
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Time</div>
                    <div className="service-list-data-text">{new Date(e.date).toLocaleTimeString()}</div>
                  </div> */}
                  
                  <div className="service-list-data">
                    <div className="service-list-data-heading">Lead</div>
                    <div className="service-list-data-text">{e.status === true ? "Booked" : "Completed"}</div>
                  </div>
    
                  <div className="service-list-data">
                    <div className="service-list-data-heading">User</div>
                    <div className="service-list-data-text"> {e.user === true ? "Genuine User" : "Blocked User"}</div>
                  </div>
    
                  <div className="service-list-data-btn"><span onClick={()=>{setPopup(true); setSave(e)}}>edit</span></div>
    
                </div>
              );
            })}
          </div>
          {/* ------------------ data end -------------------- */}
        </div>
  )
}

export default FtBooking