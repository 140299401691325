import React, { useEffect, useState } from "react";

const FtContact = () => {

  // ------------------- popup -------------------

const [popup, setPopup] = useState(false)
const [save, setSave] = useState("")


   
    
  // ---------------------- fetch all -------------------------
  
  const [contact, setContact] = useState([]);

  const loadData = async () => {
    let res = await fetch("https://api.fluffytouch.in/contact", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
      },
    });
    res = await res.json();
    console.log("data fetch resp : ", res.message);
    setContact(res.data.reverse());
  };

  useEffect(() => {
    loadData();
  }, []);


  // -------------------------- update ---------------------------
  
  
 const [update, setUpdate] = useState({
  user: "",
  status: "",
});

const handleChange = (e) => {
  const value = e.target.value;
  setUpdate({
    ...update,
    [e.target.name]: value,
  });
};

const handleSubmit = async (e) => {
  // setLoading(true);

  e.preventDefault();
  try {
    const response = await fetch(`https://api.fluffytouch.in/contact/${save._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    });

    if (response.ok) {
      alert("UPDATE successfully");
      window.location.reload();
      // onClose()
    } else {
      // alert("invalid credential...");
      const res_data = await response.json();
      alert(res_data.message);
      // console.log("className=",res_data.message)
    }

    // console.log(response);
  } catch (error) {
    // console.log("catch err", error);
    alert("server error");
  }
  // setLoading(false);
};

  
  
  return (
    <div>

{
    popup===false?("")
    :(
        <div>
          <div className="update-form-main">
        <div className="update-form-content">
          <div className="update-form-close"><span onClick={()=>{setPopup(false)}}>X</span></div>
          <div className="update-form-head">{new Date(save.date).toDateString()} &nbsp; &nbsp; - &nbsp; &nbsp; {new Date(save.date).toLocaleTimeString()}</div>
          <div className="update-form-data">
            <div className="update-form-data-list">
              <div className="update-form-data-heading">Number</div>
              <div className="update-form-data-text">{save.phone}</div>
            </div>

            {/* <div className="update-form-data-list">
              <div className="update-form-data-heading">Date</div>
              <div className="update-form-data-text">{new Date(save.date).toDateString()}</div>
            </div>

            <div className="update-form-data-list">
              <div className="update-form-data-heading">Time</div>
              <div className="update-form-data-text">{new Date(save.date).toLocaleTimeString()}</div>
            </div> */}

          <form onSubmit={handleSubmit}>
            <div className="update-form-list">
              <div className="update-form-lable">User</div>
                <select name="user" required id=""  onChange={handleChange}>
                  <option selected disabled value="">--- select option --</option>
                  <option value="true">Genuine user</option>
                  <option value="false">Fake user</option>
                </select>
            </div>

            <div className="update-form-list">
              <div className="update-form-lable">Inquiry</div>
              <div className="update-form-input">
                <div><input type="radio" required name="status" value="true"  onChange={handleChange}/><span>Raised</span></div>
                <div><input type="radio" required name="status" value="false"  onChange={handleChange}/><span>Complete</span></div>
                
              </div>
             
            </div>
            <button className="update-form-button"><span>Confirm</span> </button>

            
          </form>
        </div>
        </div>
      </div>
        </div>
    )
}     
      
      
      <div className="service-heading">Fluffy Touch - inquiry</div>
      {/* ------------------ data start -------------------- */}
      <div className="service-list">
        {contact.map((e) => {
          return (
            
            
            <div
              className={
                e.user === false
                  ? "service-list-red service-list-col"
                  : e.status === false
                  ? "service-list-green service-list-col"
                  : "service-list-orange service-list-col"
              }
            >
                  <div className="service-list-head">{new Date(e.date).toDateString()}  &nbsp;  &nbsp; -  &nbsp;  &nbsp; {new Date(e.date).toLocaleTimeString()}</div>

              <div className="service-list-data">
                <div className="service-list-data-heading">Number</div>
                <div className="service-list-data-text">{e.phone}</div>
              </div>
              
              {/* <div className="service-list-data">
                <div className="service-list-data-heading">Date</div>
                <div className="service-list-data-text">{new Date(e.date).toDateString()}</div>
              </div>

              <div className="service-list-data">
                <div className="service-list-data-heading">Time</div>
                <div className="service-list-data-text">{new Date(e.date).toLocaleTimeString()}</div>
              </div> */}
              
              <div className="service-list-data">
                <div className="service-list-data-heading">Inquiry</div>
                <div className="service-list-data-text">{e.status === true ? "Raised" : "Completed"}</div>
              </div>

              <div className="service-list-data">
                <div className="service-list-data-heading">User</div>
                <div className="service-list-data-text"> {e.user === true ? "Genuine User" : "Blocked User"}</div>
              </div>

              <div className="service-list-data-btn"><span onClick={()=>{setPopup(true); setSave(e)}}>edit</span></div>

            </div>
          );
        })}
      </div>
      {/* ------------------ data end -------------------- */}
    </div>
  );
};

export default FtContact;
