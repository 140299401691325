import React from 'react'
import GmsNav from '../../components/gms/GmsNav'
import GmsContact from '../../components/gms/GmsContact'

const GmsInquiryPage = () => {
  return (
    <div>
        <GmsNav/>
        <GmsContact/>
    </div>
  )
}

export default GmsInquiryPage