import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import icon from "../../media/images/BHUMU-white-icon.png"
import icon from "../../media/images/BHUMU-white.png"


const GocNav = () => {

  
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("userEmail");
    alert("Admin logout successful");
    navigate("/");
    window.location.reload();
  };

  if(!localStorage.getItem("userAuthToken")){
    return <Navigate to="/"/>
  }
  
  
  return (
    <div>
         <div className="navbar">
      <div className="nav">
        {/* <Link to="/home" className="nav-logo">BHUMU</Link> */}
        <Link to="/home" className="nav-logo">
          <img src={icon} alt="" />
        </Link>
        <div className="nav-menu">
          <div className="nav-menu-options">
            <Link to="/garage">Bookings</Link>
            <Link to="">Pending-bookings</Link>
            <Link to="">Completed-bookings</Link>
            <Link to="/garage-inquiry">Inquiry</Link>
            <Link to="">Pending-inquiry</Link>
            <Link to="">Completed-inquiry</Link>
            {/* <Link to="">Numbers</Link>
            <Link to="">Blocked</Link> */}
            <div  onClick={handleLogout}>Logout</div>
          </div>
          <div className="nav-menu-drop">
          <div className="nav-menu-drop-icon">=</div>
            
            <div className="nav-menu-drop-options">
            <Link to="/garage">Bookings</Link>
            <Link to="">Pending-bookings</Link>
            <Link to="">Completed-bookings</Link>
            <Link to="/garage-inquiry">Inquiry</Link>
            <Link to="">Pending-inquiry</Link>
            <Link to="">Completed-inquiry</Link>
            <div  onClick={handleLogout}>Logout</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default GocNav