import React, { useState } from "react";
import logo from "../../media/images/BHUMU-black.png"
import { Navigate, useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";


const LoginPage = () => {

  const [loading, setLoading] = useState(false);

  // ------------------------------------
  
  const navigate = useNavigate()


    
  const [user, setUser] = useState({
    numberOrEmail: "",
    password: "",
  });
  
  
  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value
    });
  };


  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();

    try {
      // const response = await fetch("http://localhost:4000/login", {
      const response = await fetch("https://api.dashboard.bhumu.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
 
      if (response.ok) {
        
        const res_data = await response.json();
        // console.log("res from server", res_data) 
        
        // localStorage.setItem('token', res_data.token);
        
        
        // console.log("resp = ", response)
        // console.log("23414", user)
        localStorage.setItem("userAuthToken", res_data.token)
        // localStorage.setItem("userEmail", user.phone)
        // console.log("token = ", res_data.token)
        

        // setUser({ email: "", password: "" });
        
        // localStorage.setItem("Auth Token", json.authToken)
        // console.log("at- ", localStorage.getItem('Auth Token'))

        navigate("/home");
        alert("login sucessful");
      } else {
        // console.log("invalid credential...");
        const res_data = await response.json();
        // console.log("err res", response)
        // console.log("err res data", res_data)
        alert(res_data.message)
        console.log("Login Error : ", res_data.message)
      }

      // console.log("try res ", response);
    } catch (error) {
      console.log("catch err" , error);
      alert("server error")
    }
    setLoading(false)
  };
  

  if(localStorage.getItem("userAuthToken")){
    return <Navigate to="/home"/>
  }
  return (
    <div>
       {
        loading?
        <LoadingComponent/>
        :""
      }
      <div className="login-main">
        <div className="login-header">
            <img src={logo} alt="" />
        </div>
        <div className="login-content">
          <div className="login-content-heading-1">Welcome Back!</div>
          <div className="login-content-heading-2">Login to your account.</div>

          <div className="login-form">
            <form onSubmit={handleSubmit}>
              <div className="login-form-input">
                <span><i class="fa-solid fa-user"></i></span>
                <input required type="text" placeholder="email or mobile number" name="numberOrEmail" onChange={handleChange} />
              </div> 
              <div className="login-form-input">
                <span><i class="fa-solid fa-lock"></i></span>
                <input required type="password" placeholder="password" name="password" onChange={handleChange} />
              </div>

              <button>Log in</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
