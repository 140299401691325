import React from 'react'
import FtNav from '../../components/ft/FtNav'
import FtPendingBookings from '../../components/ft/FtPendingBookings'

const FtPendingBookingPage = () => {
  return (
    <div>

        <FtNav/>
        <FtPendingBookings/>
        
    </div>
  )
}

export default FtPendingBookingPage