import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import icon from "../../media/images/BHUMU-white-icon.png"
import icon from "../media/images/BHUMU-white.png";

const NavBar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("userEmail");
    alert("Admin logout successful");
    navigate("/");
    window.location.reload();
  };

  if (!localStorage.getItem("userAuthToken")) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div className="navbar">
        <div className="nav nav-bar">
          {/* <Link to="/home" className="nav-logo">BHUMU</Link> */}
          <Link to="/home" className="nav-logo">
            <img src={icon} alt="" />
          </Link>
          <div className="nav-menu">
            <div className="nav-menu-options">
              <Link to="/goc">Garage On Call</Link>
              <Link to="/ft">Fluffy Touch</Link>
              <Link to="/gms">Glazma</Link>
            </div>
          </div>
              <div className="nav-bar-button" onClick={handleLogout}>Logout</div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
