import React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom';

// ---------------------------- ------------------ CSS -------------------------------------------------

import './App.css';
import "../src/css/HomePage.css"
import "../src/css/NavComponent.css"
import "../src/css/NavBar.css"
import "../src/css/Table.css"
import "../src/css/ContactComponent.css"
import "../src/css/UpdatePopup.css"
import "../src/css/LoginPage.css"
import "../src/css/Loading.css"


// ---------------------------------------------- PAGES -------------------------------------------------
import HomePage from './pages/HomePage';
import Error from './pages/Error';
import OtherPage from './pages/OtherPage';
import FtInquiryPage from './pages/fluffy-touch/FtInquiryPage';
import FtBookingPage from './pages/fluffy-touch/FtBookingPage';
import GmsBookingPage from './pages/glazma/GmsBookingPage';
import GocBookingPage from './pages/garage/GocBookingPage';
import GmsInquiryPage from './pages/glazma/GmsInquiryPage';
import GocInquiryPage from './pages/garage/GocInquiryPage';
import LoginPage from './pages/admin/LoginPage';
import FtPendingBookingPage from './pages/fluffy-touch/FtPendingBookingPage';
import FtCompletedBookingPage from './pages/fluffy-touch/FtCompletedBookingPage';
import FtCompleteContactPage from './pages/fluffy-touch/FtCompleteContactPage';
import FtPendingContactPage from './pages/fluffy-touch/FtPendingContactPage';
import GocLeads from './pages/leads/GocLeads';
import FtLeads from './pages/leads/FtLeads';
import GmsLeads from './pages/leads/GmsLeads';

const App = () => {
  return (
    <div>
       <BrowserRouter >
      <Routes>
        
      <Route path='/' element={<LoginPage />}></Route>
      <Route path='/home' element={<HomePage />}></Route>

      {/* -------------------- fluffy touch -------------------- */}
      <Route path='/fluffy' element={<FtBookingPage />}></Route>
      <Route path='/fluffy-pending-bookings' element={<FtPendingBookingPage />}></Route>
      <Route path='/fluffy-completed-bookings' element={<FtCompletedBookingPage />}></Route>
      <Route path='/fluffy-inquiry' element={<FtInquiryPage />}></Route>
      <Route path='/fluffy-pending-inquiry' element={<FtPendingContactPage />}></Route>
      <Route path='/fluffy-completed-inquiry' element={<FtCompleteContactPage />}></Route>



      {/* -------------------- Glazma -------------------- */}
      <Route path='/glazma' element={<GmsBookingPage />}></Route>
      <Route path='/glazma-inquiry' element={<GmsInquiryPage />}></Route>




      {/* -------------------- Garage -------------------- */}
      <Route path='/garage' element={<GocBookingPage />}></Route>
      <Route path='/garage-inquiry' element={<GocInquiryPage />}></Route>



      <Route path='/goc' element={<GocLeads/>}></Route>
      <Route path='/ft' element={<FtLeads/>}></Route>
      <Route path='/gms' element={<GmsLeads/>}></Route>
      
      
      {/* <Route path='/other' element={<OtherPage />}></Route> */}
      
      {/* <Route path='/goc' element={<OtherPage />}></Route> */}
      <Route path='*' element={<Error />}></Route>
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App