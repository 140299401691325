import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import bhumu from "../media/images/0000.png"
import bhumu from "../media/images/bhumu logo.png"
import ft from "../media/images/ft.png"
import goc from "../media/images/goc.png"
import gms from "../media/images/glazma.png"

const HomePage = () => {

  
  
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("userEmail");
    alert("Admin logout successful");
    navigate("/");
    window.location.reload();
  };

  
  if(!localStorage.getItem("userAuthToken")){
    return <Navigate to="/"/>
  }
  return (
    <div>
      <div className="home-page">
        
      {/* <div className="home-page-header">BHUMU</div> */}
      <div className="home-page-header"><img src={bhumu} alt="" /></div>

        <div className="home-page-cols">

        {/* <Link to="/glazma"><img src={gms} alt="Glazma" /></Link>
        <Link to="/garage"><img src={goc} alt="Garage On call" /></Link>
        <Link to="/fluffy"><img src={ft} alt="Fluffy Touch" /></Link> */}
          
        <Link to="/gms"><img src={gms} alt="Glazma" /></Link>
        <Link to="/goc"><img src={goc} alt="Garage On call" /></Link>
        <Link to="/ft"><img src={ft} alt="Fluffy Touch" /></Link>
          
        {/* <Link to="/gms"><img src={gms} alt="" /><span>Glazma</span></Link>
        <Link to="/goc"><img src={goc} alt="" /><span>Garage On Call</span></Link>
        <Link to="/ft"><img src={ft} alt="" /><span>Fluffy Touch</span></Link> */}
        </div>
      <div className="home-page-logout"><span onClick={handleLogout}>Logout</span></div>
      </div>
    </div>
  );
};

export default HomePage;
