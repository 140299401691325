import React from 'react'
import GocNav from '../../components/goc/GocNav'
import GocBooking from '../../components/goc/GocBooking'

const GocBookingPage = () => {
  return (
    <div>
      <GocNav/>
      <GocBooking/>
    </div>
  )
}

export default GocBookingPage