import React from 'react'
import FtNav from '../../components/ft/FtNav'
import FtContact from '../../components/ft/FtContact'

const FtInquiryPage = () => {
  return (
    <div>
        <FtNav/>
        <FtContact/>
    </div>
  )
}

export default FtInquiryPage